import { Inject, Injectable, Injector } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { Layout, MenuService, SettingsService, TitleService, User } from '@delon/theme';
import { environment } from '@env/environment';
import { PlatformMenuEntry } from '@env/interface';
import { TranslateService } from '@ngx-translate/core';
import concat from 'lodash/concat';
import { NzIconService } from 'ng-zorro-antd/icon';
import { Observable, combineLatest, merge, of } from 'rxjs';
import { bufferTime, catchError, filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';
import { MenuEntry, Organization, OrganizationRole, PlatformRole } from 'src/app/graphql/frontend-data-graphql';
import { AuthService, CategoryMenuEntry } from 'src/app/shared/services/auth.service';
import { CacheService } from 'src/app/shared/services/cache.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { ICONS_CUSTOM } from 'src/style-icons-custom';

import { ICONS } from '../../../style-icons';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { MosaicAppSettings } from '../app.state';

/**
 * Used for application startup
 * Generally used to get the basic data of the application, like: Menu Data, User Data, etc.
 */
@Injectable({ providedIn: 'root' })
export class StartupService {
  constructor(
    iconSrv: NzIconService,
    private menuService: MenuService,
    private settingService: SettingsService<Layout, User, MosaicAppSettings>,
    private titleService: TitleService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private translateService: TranslateService,
    private auth: AuthService,
    private injector: Injector,
    private documentService: DocumentService,
    private cacheService: CacheService
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
    for (const icon of ICONS_CUSTOM) {
      iconSrv.addIconLiteral(icon.type, icon.literal);
    }
  }

  frontend_category_settings$: Observable<Record<string, CategoryMenuEntry>>;
  admin_menu_entries$: Observable<MenuEntry[]>;

  public initialize(): Observable<void> {
    const token = this.tokenService.get()?.token;

    // Application information: including site name, description, year
    this.settingService.setApp({
      ...this.settingService.app,
      name: `lector.ai`,
      description: this.translateService.instant('BRAND.subtitle')
    });

    const orgaService = this.injector.get(OrganizationService);

    this.frontend_category_settings$ = this.auth.organization$.pipe(
      filter(o => o != undefined),
      switchMap(org =>
        this.cacheService.resetInProgress$.pipe(
          filter(p => !p && Boolean(this.auth.currentUser)), // Wait while reset is in progress
          switchMap(res =>
            orgaService.getById(org?.identifier ?? 'Unknown').pipe(
              map(res => res.data?.organizationByIdentifier as Organization),
              map(org => {
                const settings = org?.frontend_category_settings as Record<string, CategoryMenuEntry>;
                return settings && Object.values(settings).length > 0
                  ? settings
                  : {
                      classification: {
                        label: 'Inbox',
                        icon: 'partition',
                        link: 'classification'
                      }
                    };
              })
            )
          )
        )
      )
    );

    this.admin_menu_entries$ = this.auth.organization$.pipe(
      filter(o => o != undefined),
      switchMap(org =>
        this.cacheService.resetInProgress$.pipe(
          filter(p => !p && Boolean(this.auth.currentUser)), // Wait while reset is in progress
          switchMap(res =>
            orgaService.getById(org?.identifier ?? 'Unknown').pipe(
              map(res => res.data?.organizationByIdentifier as Organization),
              map(org => {
                const settings = org?.admin_menu_entries as MenuEntry[];
                return settings || [];
              })
            )
          )
        )
      )
    );

    combineLatest([
      this.translateService.onLangChange.pipe(startWith(this.translateService.currentLang)),
      this.auth.organization$.pipe(filter(o => o != undefined)),
      this.frontend_category_settings$,
      this.admin_menu_entries$
    ]).subscribe(([, , frontend_category_settings, admin_menu_entries]) => {
      this.menuService.clear();
      this.menuService.add([
        {
          text: this.translateService.instant('SIDEBAR.dashboard'),
          link: '/dashboard',
          group: false,
          children: [
            {
              text: this.translateService.instant('SIDEBAR.dashboard'),
              link: '/dashboard',
              icon: { type: 'icon', value: 'home' }
            }
          ]
        },
        {
          text: this.translateService.instant('SIDEBAR.documents'),
          link: '/documents',
          icon: { type: 'icon', value: 'form' },
          children: [
            {
              text: this.translateService.instant('SIDEBAR.documentCenter'),
              link: '/documents/document-center',
              icon: { type: 'icon', value: 'eye' },
              key: 'finder',
              acl: {
                role: [PlatformRole.Developer]
              }
            },
            {
              text: this.translateService.instant('SIDEBAR.overview'),
              link: '/documents',
              icon: { type: 'icon', value: 'database' },
              key: 'overview'
            },
            ...Object.entries(frontend_category_settings ?? {}).map(([k, e]) => ({
              text: e.label,
              link: `/documents/inbox/${k}`,
              icon: { type: 'icon', value: e.icon } as any,
              key: e.regex ?? 'inbox',
              acl:
                e.platform_roles || e.organization_roles
                  ? {
                      role: concat((e.platform_roles as string[] | undefined) || [], (e.organization_roles as string[] | undefined) || [])
                    }
                  : undefined
            }))
          ]
        },
        {
          text: this.translateService.instant('SIDEBAR.administration'),
          icon: { type: 'icon', value: 'setting' },
          acl: {
            role: [PlatformRole.Developer, PlatformRole.PlatformAdmin, OrganizationRole.OrganizationAdmin]
          },

          children: [
            {
              text: this.translateService.instant('SIDEBAR.users'),
              link: '/admin/users',
              icon: { type: 'icon', value: 'team' }
            },
            {
              text: this.translateService.instant('SIDEBAR.organizations'),
              link: '/admin/organizations',
              icon: { type: 'icon', value: 'bank' }
            },
            {
              text: this.translateService.instant('SIDEBAR.predictors'),
              link: '/admin/predictors',
              icon: { type: 'icon', value: 'robot' }
            },
            {
              text: this.translateService.instant('SIDEBAR.exporters'),
              link: '/admin/exporters',
              icon: { type: 'icon', value: 'export' }
            },
            {
              text: this.translateService.instant('SIDEBAR.notifications'),
              link: '/admin/notifications',
              icon: { type: 'icon', value: 'bell' }
            },
            {
              text: this.translateService.instant('SIDEBAR.classes'),
              link: '/admin/document-class-fs',
              icon: { type: 'icon', value: 'folder' }
            },
            {
              text: 'Workflow',
              link: '/admin/workflow',
              icon: { type: 'icon', value: 'apartment' },
              acl: {
                role: [PlatformRole.Developer]
              }
            },
            ...Object.entries(admin_menu_entries ?? {}).map(([k, e]) => ({
              text: e.label,
              link: `/external/${e.label}?value=${e.link}`,
              icon: { type: 'icon', value: e.icon } as any,
              key: e.label,
              acl:
                e.platform_roles || e.organization_roles
                  ? {
                      role: concat((e.platform_roles as string[] | undefined) || [], (e.organization_roles as string[] | undefined) || [])
                    }
                  : undefined
            }))
          ]
        },
        {
          text: 'Plattform',
          children: [
            {
              text: 'Anleitung',
              externalLink: '/docs',
              target: '_blank',
              icon: { type: 'icon', value: 'question-circle' }
            },
            ...(environment.platform_menu_entries ?? []).map(
              (e: PlatformMenuEntry) =>
                ({
                  text: e.label,
                  target: '_blank',
                  externalLink: e.forward_token
                    ? `${e.url}/?${new URLSearchParams({
                        auth_token: token || ''
                      }).toString()}`
                    : e.url,
                  icon: { type: 'icon', value: e.icon },
                  acl:
                    e.platform_roles || e.organization_roles
                      ? {
                          role: concat(
                            (e.platform_roles as string[] | undefined) || [],
                            (e.organization_roles as string[] | undefined) || []
                          )
                        }
                      : undefined
                }) as any
            ),
            {
              text: 'GraphiQL',
              target: '_blank',
              icon: { type: 'icon', value: 'play-circle' },
              acl: {
                role: [PlatformRole.Developer]
              },
              children: [
                {
                  text: 'frontend-data',
                  externalLink: `${environment.api.baseUrl}${environment['playground_url']}/?${new URLSearchParams({
                    endpoint: '/api/frontend-data/v1/graphql',
                    token: token || ''
                  }).toString()}`,
                  target: '_blank',
                  icon: { type: 'icon', value: 'play-circle' },
                  acl: {
                    role: [PlatformRole.Developer]
                  }
                },
                {
                  text: 'history',
                  externalLink: `${environment.api.baseUrl}${environment['playground_url']}/?${new URLSearchParams({
                    endpoint: '/api/history/v1/graphql',
                    token: token || ''
                  }).toString()}`,
                  target: '_blank',
                  icon: { type: 'icon', value: 'play-circle' },
                  acl: {
                    role: [PlatformRole.Developer]
                  }
                },
                {
                  text: 'governor',
                  externalLink: `${environment.api.baseUrl}${environment['playground_url']}/?${new URLSearchParams({
                    endpoint: '/api/governor/v1/graphql',
                    token: token || ''
                  }).toString()}`,
                  target: '_blank',
                  icon: { type: 'icon', value: 'play-circle' },
                  acl: {
                    role: [PlatformRole.Developer]
                  }
                },
                {
                  text: 'document-collector',
                  externalLink: `${environment.api.baseUrl}${environment['playground_url']}/?${new URLSearchParams({
                    endpoint: '/api/document-collector/v1/graphql',
                    token: token || ''
                  }).toString()}`,
                  target: '_blank',
                  icon: { type: 'icon', value: 'play-circle' },
                  acl: {
                    role: [PlatformRole.Developer]
                  }
                }
              ]
            }
          ]
        }
        // {
        //   text: 'Support',
        //   children: [
        //     {
        //       text: 'Support',
        //       link: '/help',
        //       icon: { type: 'icon', value: 'comment' },
        //     },
        //   ],
        //   group: false,
        // },
      ]);
    });

    combineLatest([this.auth.scope$.pipe(filter(o => o != undefined)), this.auth.organization$.pipe(filter(o => o != undefined))])
      .pipe(
        switchMap(([scope, org]) =>
          merge(
            this.documentService.deletedOneDocument.subscribe({
              filter: {
                scope: { eq: scope }
              }
            }),
            this.documentService.updatedOneDocument$,
            this.documentService.createdDocument.subscribe({
              filter: {
                scope: { eq: scope }
              }
            })
          ).pipe(
            startWith([]),
            bufferTime(2000),
            startWith(['x']), // Make sure it loads fast on the first load
            filter(res => res.length > 0),
            filter(() => {
              return this.auth.token != undefined;
            }),
            mergeMap(() =>
              this.documentService.getAllDocumentCounts
                .mutate({ scope: scope! })
                .pipe(map(res => res.data?.getAllDocumentCounts))
                .pipe(catchError(() => of([])))
            )
          )
        ),
        tap(counts => {
          counts?.forEach(entry => {
            this.menuService.setItem(entry.regex ?? 'inbox', {
              badge: entry.count,
              badge_tag: entry.tag_counts.filter(t => t.email == this.auth.currentUser.email)[0]?.count,
              badge_snooze: entry.snoozed_count
            });
          });
        })
      )
      .subscribe();

    // Can be set page suffix title, https://ng-alain.com/theme/title
    this.titleService.suffix = this.settingService.app.name ?? '';

    return of();
  }
}
